import "./text.css"

function text() {
  return (
    <>
    <div className="Container">
    <p className="Text">Doc.</p>
        <p className="testings3">Hello welcome to my MERN auth documentation!</p> 
        <br></br>
        <a href="https://github.com/kevin-le1/mern_authen" className="links">𝐆𝐢𝐭𝐡𝐮𝐛</a>

        <p className="Text" >What I learned / What I did</p>
        <p className="testings3">For my work, I had to utilize MERN stack quite frequently, utilizing MongoDB as the database and utilizing express/node.js to properly faciliate the data. In doing so, this project is a quick template as to how to properly set up authentication. To elaborate a bit on why I did this, I wanted a template to review if I ever needed a quick review on MERN stack. Some good practice is to create proper environments/parsing authentication credentials into tokens and ids. These were some of the things that I implemented to learn upon and just do to become a better coder. This project allowed me to get a better understanding of MERN, more specifically the backend express/node.js proportion of coding. As previously, I typically utilized Django and its well built library to create apis facilitate the same thing.</p>
        <br></br>
        <br></br>
        <img src="catread.png" className="imgs10"></img>
    </div>
    </>
  );
}

export default text;
