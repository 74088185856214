import "./text.css"

function text() {
  return (
    <>
    <div className="Container">
    <p className="Text">Doc.</p>
        <p className="testings3">Hello welcome to my Val DS documentation!</p> 
        <br></br>
        <a href="https://github.com/kevin-le1/val_data" className="links">𝐆𝐢𝐭𝐡𝐮𝐛</a>

        <p className="Text" >What I learned / What I did</p>
    <p className="testings3">This quick DS project was purely for fun due to my boredom! I wanted to do some quick EDA just to refresh upon my python library skills. In doing so, I simply refined my skills and practiced EDA!</p>
        <br></br>
        <br></br>
        <img src="catread.png" className="imgs10"></img>
    </div>
    </>
  );
}

export default text;
