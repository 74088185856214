import "./divider.css"

function Divider() {
  return (
    <>
    <img src="whitedivider.png" className="img2"></img>

    </>
  );
}

export default Divider;
