import "./map.css"

function Home() {
  return (
    <>
    <div className="spacing3"></div>
    <img src="map.png" className="mernimg"></img>
    <div className="spacing3"></div>
    </>
  );
}

export default Home;
