import "./eg.css"

function Home() {
  return (
    <>
    <div className="spacing3"></div>
    <img src="eg.jpeg" className="mernimg"></img>
    <div className="spacing3"></div>
    </>
  );
}

export default Home;
