import "./bottom.css"

function Bottom() {
  return (
    <>
    <div className="lowerBar">
    <p className = "dark">
        This page was made by Kevin Le, © 2023
    </p>
        <a href ="https://www.linkedin.com/in/kevin-le-00071524a/" className="links">𝐋𝐢𝐧𝐤𝐞𝐝𝐢𝐧    </a>
        <a href = "https://github.com/kevin-le1" className="links">    𝐆𝐢𝐭𝐡𝐮𝐛</a>
    </div>
    <div className="">

    </div>
    </>
  );
}

export default Bottom;
