
import { Marker, TileLayer, MapContainer, Popup} from "react-leaflet";
import "leaflet/dist/leaflet.css"
import './map.css'
import {Icon} from 'leaflet'
import Mark from './marker.webp'
//  icon={new Icon({iconUrl: './marker.webp', iconSize: [25, 41], iconAnchor: [12, 41]})}

export default function Map() {
  
  const location = [37.0902, -95.7129]
  const princeton =[40.3431, -74.6551]
  const sanjose =[37.3387, -121.8853]
  const ten = [35.1, -86.1122]
  
    return(
    <>
    <div className='map-container'>
    <div className="map-text">
    Map of my life!
  </div>
  <div className="spacing2"></div>
    <div className="map-box">
        
        <MapContainer center={location} zoom={4} scrollWheelZoom={false} style={{width: '100%', height:'100%'} } > 
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' 
          url="https://api.maptiler.com/maps/basic-v2-light/{z}/{x}/{y}.png?key=ZZyAQlM36jevo6tFrGct" />
        
        <Marker position={sanjose} icon={new Icon({iconUrl: Mark, iconSize: [20, 20]})}>
        <Popup>
            Birth Place & Adulthood!
            <br></br>
            Location: San Jose
            <br></br>
            Longitude: {sanjose[0]}° N, Latitude: {-1*sanjose[1]}° W
        </Popup>
        </Marker>
        <Marker position={ten} icon={new Icon({iconUrl: Mark, iconSize: [20, 20]})}>
        <Popup>
            Adolescence!
            <br></br>
            Location: Tennessee
            <br></br>
            Longitude: {ten[0]}° N, Latitude: {-1*ten[1]}° W
        </Popup>
        </Marker>
        <Marker position={princeton} icon={new Icon({iconUrl: Mark, iconSize: [20, 20]})}>
        <Popup>

            Princeton University, Current Education!
            <br>
            </br>
            Location: Princeton
            <br></br>
            Longitude: {princeton[0]}° N, Latitude: {-1*princeton[1]}° W
            
        </Popup>
        </Marker>
        </MapContainer>
        
    </div>
    </div>
    <div className="spacing"></div>
    </>
    )
}
