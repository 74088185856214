import Layout from './Layout'
import EG from './EGProj'
import OpenStreetMap from './openstreetmap'
import Valorant from './Valorant'
import Mern from './mern'
import "./index.css"
import {Route, Routes} from "react-router-dom"
// <Route path ="/login" element ={<Login />}/>
function App() {
  return (
    <Routes>
        <Route path = "/" element ={<Layout />}></Route>
        <Route path = "/EG" element ={<EG />}></Route>
        <Route path = "/openstreetmap" element ={<OpenStreetMap />}></Route>
        <Route path = "/valorant" element ={<Valorant />}></Route>
        <Route path = "/mern" element ={<Mern />}></Route>
    </Routes>
  );
}

export default App;
