import "./mern.css"

function Home() {
  return (
    <>
    <div className="spacing3"></div>
    <img src="mern.webp" className="mernimg"></img>
    <div className="spacing3"></div>
    </>
  );
}

export default Home;
