import "./home.css"

function Home() {
  return (
    <>
    <div className="spacing3"></div>
    <img src="black.png" className="img"></img>
    <div className="spacing3"></div>
    </>
  );
}

export default Home;
