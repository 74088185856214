import "./text.css"

function text() {
  return (
    <>
    <div className="Container">
        <p className="Text">Overview.</p>
        <p className="testings3">Hello welcome to my openstreetmap documentation. Within this documentation, I will show my thought process and elaborate on why I am utilizing this api! If you would like a quick summary, the link will be pasted right below here!~</p> 
        <a href = "https://docs.google.com/document/d/1H0vtFodZGIdxKDPshzHj7TCVLIJ3Ue_GKfedihymlRg/edit" className="links">𝐃𝐨𝐜𝐮𝐦𝐞𝐧𝐭</a>
        <br></br>
        <a href="https://github.com/kevin-le1/openstreetmap" className="links">𝐆𝐢𝐭𝐡𝐮𝐛</a>
        <p className="Text">Why?</p>
        <p className="testings3">Let me first start with the why? From creating a lot of REST APIs due to my previous work, I wanted to build upon this knowledge and try to utilize prebuilt APIs. Thus, I started looking more into the avenues of APIs, leading me to create a website that properly fetches the information from the given API, through meticulously working with the query. </p>
        <p className="Text" >What I learned?</p>
        <p className ="testings3">From becoming better at utilizing React, specifically passing type objects (defining types), having better coding practices, or more definitively learning the functionality, use, and application of this api, I really enjoyed this project! Additionally, as you can see with the use of openstreetmap on my website, I have utilized my previous knowledge of the openstreetmap api and utilized it here as well! Though the better version/usage of the api is displayed on my github!</p>
        <p className="Text">Documentation!</p>
        <p className="testings3">Now, more for myself and interested readers here is the documentation of how I proceeded! Though, I will not be explaining the passed parameters/type objects. I first started by creating a yarn project (utilizing yarn create). I additionally utilized the openstreetmap website installation documentation to both install all the dependencies and see a quick preview of the map. The initial portion was to set up the map, along with defining the css as well. The more difficult portion of this would definitely be the "search" implementation (seen in Search.tsx) and additionally the nominatim search query.</p>
        <img src="mapcode.png" className="imgs1"></img>
        <p className="testings3">This code was the bulk of fetching the information from the API (nominatim)</p>
        <p className="testings3">Within nominatim, there is a quick example of how the api works, passing in parameters of a query, format, addressdetails, and poly_geoson, though in this project since I didn't require information from poly_geoson, I chose to ignore it. So, once the user inputs the texts and clicks this search button, the user's input is passed through, and GETs the information from the API (generally calling nominatimurl / query / format / addressdetails / polygeoson) and parses the json results. </p>
        <br></br>
        <br></br>
        <img src="catread.png" className="imgs10"></img>
    </div>
    </>
  );
}

export default text;
