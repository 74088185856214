import "./text.css"

function text() {
  return (
    <>
    <div className="Container">
    <p className="Text">Doc.</p>
        <p className="testings3">Hello welcome to my EG DS documentation!</p> 
        <br></br>
        <a href="https://github.com/kevin-le1/EG" className="links">𝐆𝐢𝐭𝐡𝐮𝐛</a>
        <p className="Text" >What I learned / What I did</p>
        <p className="testings3"> Let me first start with what I learned! Coming into this project I initially had to work with a parquet file which required pandas (one of the main python library I used in this project) to read through. After reading and storing this data as an array, I then manipulated it into segments, auxiliary arrays to be used for future potential information. I then utilized geometry to work with the following auxiliary and main array. Then, I created numerous methods to be called to work with the dataset. </p>
        <br></br>
        <br></br>
        <img src="catread.png" className="imgs10"></img>
    </div>
    </>
  );
}

export default text;
